import React, { Component } from "react";
import clsx from 'clsx';
import { observer, inject, Provider } from "mobx-react";
import { Grid, Drawer, Typography, IconButton, LinearProgress, CircularProgress, Backdrop, Tabs, Tab, Box } from "@material-ui/core"
import { AccountCircle, Close, SaveAlt } from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import Dashboardstore from "../../stores/dashboardstore";
import Categorization from "./Categorization";
import Audience from "./Audience";
import * as d3 from 'd3';
import TopFriends from "./TopFriends";
import TopAffinities from "./TopAffinities";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{"width": "100%"}}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const makeDrawerWidth = props => {
 if (isWidthUp('lg', props.width)) {
   return '25%'
 }
 if (isWidthUp('md', props.width)) {
   return '50%'
 }
 return '100%'
}

const styles = theme => ({
  drawer: {
    width: makeDrawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: makeDrawerWidth,
  },
  drawerOpen: {
    width: makeDrawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    marginLeft: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(9) + 1,
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})


@inject('routing')
@observer
class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.store = new Dashboardstore({routing: props.routing})
    this.colorRange = ["#d7191c", "#ffffbf", "#1a9641"]; //['#ffffcc', '#a1dab4', '#41b6c4', '#2c7fb8', '#253494']
  }

  scaleLinearColor = (value, domain) => {
    // console.log(value, domain)
    const color = d3
      .scaleLinear()
      .range(this.colorRange)
      .domain(domain);
    const result = color(value);
    // console.log(result);
    return result;
  }

  scaleQuantileColor = (value, domain) => {
    // console.log(value, domain)
    const color = d3
      .scaleQuantile()
      .range(this.colorRange)
      .domain(domain);
    const result = color(value);
    // console.log(result);
    return result;
  }

  scalePowerColor = (value, domain, exponent) => {
    // console.log(value, domain)
    const color = d3
      .scalePow()
      .exponent(exponent)
      .range(this.colorRange)
      .domain(domain);
    const result = color(value);
    // console.log(result);
    return result;
  }

  render() {
    const open = this.store.drawerOpen;
    const classes = this.props.classes;
    const specs = this.store.specialties;
    const cats = this.store.audience_categories;
    const values = cats.map((entry) => (
      entry.score
    ))
    const linearDomain = [Math.min(...values), d3.mean(values), Math.max(...values)]
    return (
      <Provider dashboardStore={this.store}>

        <Grid container spacing={1}>
          <Grid container item xs={12} justify={'flex-end'}>
            <Tabs value={this.store.source} onChange={this.store.setSource}>
              <Tab label="Twitter" />
              {/*<Tab label="Statsocial" />*/}
            </Tabs>
          </Grid>
          <Drawer
            variant="permanent"
            ModalProps={{keepMounted: true}}
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <div style={{marginTop: 48}}>
              {open ?
                (
                  <Grid container item xs={12}>
                    <Grid container item xs={12} justify={'flex-end'}>
                      <IconButton onClick={this.store.closeDrawer}>
                        <Close/>
                      </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                      <Audience store={this.store} specs={specs} />
                    </Grid>
                  </Grid>
                )
                :
                  <Grid container item xs={12} direction={'column'} justify={"space-between"} alignItems={"center"} style={{height: 'calc(100vh - 60px)'}}>
                    <Grid item>
                      <IconButton color={"secondary"} onClick={this.store.openDrawer}>
                        <Grid container item xs={12} justify={'center'}>
                          <Grid item xs={12}>
                            <AccountCircle />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant={"caption"}>audience</Typography>
                          </Grid>
                        </Grid>
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton color={"primary"} disabled={this.store.audience_categories.length === 0} onClick={this.store.exportData}>
                        <SaveAlt/>
                      </IconButton>
                    </Grid>
                  </Grid>
              }
            </div>
          </Drawer>
          <TabPanel value={this.store.source} index={0}>
            <div className={classes.content}>
              <Grid container item xs={12} spacing={0}>
                <Grid item lg={8} xs={12}>
                  {this.store.audience_categories.length > 0 ?
                    <Categorization
                      store={this.store}
                      domain={linearDomain}
                      experiment={this.store.experimentIds}
                      normative={this.store.normativeIds}
                      expType={this.store.experimentIdType}
                      normType={this.store.normativeIdType}
                    />
                  :
                    <Grid container justify={"center"}>
                      <Grid item>
                        <Typography variant={"h6"}>
                          Select an Experiment Audience
                        </Typography>
                        {this.store.analyzing &&
                          <LinearProgress color={'secondary'}/>
                        }
                      </Grid>
                    </Grid>
                  }
                </Grid>
                <Grid item lg={4} xs={12}>
                  <TopFriends
                    experiment={this.store.experimentIds}
                    normative={this.store.normativeIds}
                    expType={this.store.experimentIdType}
                    normType={this.store.normativeIdType}
                  />
                </Grid>
              </Grid>
            </div>
          </TabPanel>
          <TabPanel value={this.store.source} index={1}>
            <div className={classes.content}>
              <Grid container item xs={12} spacing={1}>
                <TopAffinities
                  experiment={this.store.experimentIds}
                  normative={this.store.normativeIds}
                  expType={this.store.experimentIdType}
                  normType={this.store.normativeIdType}
                />
              </Grid>
            </div>
          </TabPanel>
          <Backdrop className={classes.backdrop} open={this.store.loading}>
            <Grid container direction="column" justify={"center"} alignContent={"center"} alignItems={"center"}>
              <Grid item>
                <CircularProgress/>
              </Grid>
              <Grid item>
                <Typography>Loading Taxonomies & Categorization</Typography>
              </Grid>
            </Grid>
          </Backdrop>
        </Grid>
    </Provider>
    )
  }
}

export default withWidth()(withStyles(styles)(Dashboard));