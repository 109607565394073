import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Grid, Avatar, Typography, Paper, LinearProgress, Divider, Collapse} from "@material-ui/core";
import { Twitter, Warning, ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import accountstore from "../../../stores/accountstore";
import AccountCategorization from "./AccountCategorization";

const styles = theme => ({
});

function nFormatter(num) {
     if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
     }
     if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
     }
     if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
     }
     if (num >= 1) {
        if (Number.isInteger(num)) {
          return num.toFixed(0)
        } else {
          return num.toFixed(1);
        }
     }
     if (num >= 0.01) {
       return num.toFixed(2);
     }
     return num.toFixed(3);
}


@inject('dashboardStore')
@inject('routing')
@observer
class Account extends Component {

  constructor(props) {
    super(props);
    this.store = new accountstore(props.routing, props.profile, props.count, props.chinorm, 0, props.hydrator, props.dashboardStore);
  }

  render() {
    const {profile, count, chinorm, loading, error} = this.store;
    return (
      <Paper style={{padding: 4, backgroundColor: !this.store.categorized ? "#c0392b" : "#2f3640"}}>
        {loading ?
          <LinearProgress/>
          :
          error ?
              <Grid container item xs={12} spacing={1} alignItems={"center"}>
                <Grid item>
                  <Warning/>
                </Grid>
                <Grid item>
                  <Typography variant={"caption"}>Unable to lookup account.</Typography>
                </Grid>
              </Grid>
            :
              <Grid container item xs={12} alignItems={'center'} justify={"space-between"}>
                {!this.store.categorized &&
                  <Grid container item xs={12} spacing={1} alignItems={"center"}>
                    <Grid item>
                      <Warning/>
                    </Grid>
                    <Grid item>
                      <Typography variant={"caption"}>THIS ACCOUNT IS UNCATEGORIZED</Typography>
                    </Grid>
                  </Grid>
                }
                <Grid container item xs justify={'center'} direction={'column'} alignItems={'center'} spacing={1}>
                  <Grid item xs={12} md>
                    <a style={{cursor: 'pointer', textDecoration: 'inherit', color: 'inherit'}} href={`https://www.twitter.com/${profile.screen_name}`} target="_blank" rel="noopener noreferrer">
                      <Avatar src={profile.image_url}/>
                    </a>
                  </Grid>
                  <Grid item>
                    <Typography variant={'caption'}>{profile.screen_name}</Typography>
                  </Grid>
                  <Grid item>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}>
                        <Twitter style={{fontSize: 14}}/>
                        <Typography variant={'caption'} style={{paddingLeft: 4}}>{nFormatter(profile.followers_count)}</Typography>
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Typography variant={"caption"}>
                    {profile.description}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider light/>
                </Grid>
                <Grid container item xs={12} justify={"space-between"} alignItems={'center'} direction={"row"}>
                  <Grid container item xs alignItems={"center"}>
                    <Grid item onClick={this.store.toggleCategoryExpand}>
                      {this.store.categorizationExpanded ? <ArrowDropUp/> : <ArrowDropDown/>}
                    </Grid>
                    <Grid item>
                      <Typography variant={"caption"}>Categorization</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs>
                    <span>
                      <Typography style={{paddingRight: 4}} variant={"caption"}>Audience Count:</Typography>
                      <Typography style={{fontWeight: 'bold'}} variant={"caption"}>{nFormatter(count)}</Typography>
                    </span>
                    <span style={{paddingLeft: 8}}>
                      <Typography style={{paddingRight: 4}} variant={"caption"}>Specificity:</Typography>
                      <Typography style={{fontWeight: 'bold'}} variant={"caption"}>{nFormatter(chinorm)}</Typography>
                    </span>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Collapse in={this.store.categorizationExpanded}>
                    <AccountCategorization store={this.store}/>
                  </Collapse>
                </Grid>
              </Grid>
        }
      </Paper>
    )
  }
}

export default withStyles(styles)(Account);