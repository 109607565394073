import { observable, action, computed } from "mobx";
import Simdoc from "../services/simdoc";


export default class AccountStore {
  @observable loading = true;
  @observable categorizationLoading = false;
  @observable error = false;
  @observable profile = {};
  @observable categorization = {};
  @observable categorized = false;
  @observable count = 0;
  @observable chinorm = 0;
  @observable chigenpop = 0;
  @observable categorizationExpanded = false;

  constructor(routing, profile, count, chi_norm, chi_genpop, hydrator, dashboardStore) {
    this.routing = routing;
    this.simdoc = new Simdoc(routing);
    this.hydrator = hydrator;
    this.count = count
    this.chinorm = chi_norm
    this.chigenpop = chi_genpop
    this.dashboardStore = dashboardStore;
    this.load_profile(profile).then(() => {
      this.loading = false
      this.error = false
    }).catch(() => {
      this.error = true;
      this.loading = false;
    })
  }

  @computed
  get categories() {
    return this.dashboardStore.audience_categories;
  }

  @action
  initCategorization() {
    this.categorizationLoading = true;
    this.loadAccountCategorization()
      .then(() => (
      this.categorizationLoading = false
      )).catch((e) => (
      this.categorizationLoading = false
    ));
  }

  @action
  loadAccountCategorization = async () => {
    const response = await this.simdoc.getAccount(this.profile.id);
    this.categorization = response.data.categorization;
  }

  @computed
  get accountCategorization() {
    const results = {};
    for (const acct of this.categories) {
      results[acct.category] = this.categorization[acct.category]
    }
    return results;
  }

  @action
  toggleCategoryExpand = e => {
    if (this.categorizationLoading) {
      return
    }
    this.categorizationExpanded = !this.categorizationExpanded;
    if (this.categorizationExpanded) {
      this.initCategorization()
    }
  }

  @action.bound
  async load_profile(profile) {
    this.loading = true;
    this.error = false;
    if (profile.screen_name) {
      this.profile = profile;
      this.categorized = true;
      return true;
    }
    const response = await this.hydrator.add(profile.id);
    if (response) {
      this.profile = response
      this.categorized = false;
    } else {
      throw new Error("Unable to Load Profile")
    }
  }

  @action
  categorize = category => {
    this.loading = true;
    this.simdoc.classifyAccount(this.profile, category).then((response) => (
      response.data
    )).then((data) => (
      this.load_profile(data)
    )).then(() => {
      this.initCategorization();
    }).then(() => {
      this.loading = false;
    })
  }

  @action
  uncategorize = category => {
    this.loading = true;
    this.simdoc.unclassifyAccount(this.profile, category).then((response) => (
      response.data
    )).then((data) => (
      this.load_profile(data)
    )).then(() => {
      this.initCategorization();
    }).then(() => {
      this.loading = false;
    })
  }

}