import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Grid, LinearProgress } from "@material-ui/core";
import CategoryResults from "./CategoryResults";


@inject('routing')
@observer
class Categorization extends Component {
  render() {
    return (
      <Grid item xs={12} container spacing={1}>
        {this.props.store.analyzing &&
          <Grid item xs={12}>
            <LinearProgress color={'secondary'}/>
          </Grid>
        }
        {Object.keys(this.props.store.categories).sort().map((name) => (
         <Grid item xs={12} md={6} key={`category-results-${name}`}>
           <CategoryResults
            name={name}
            store={this.props.store}
            labels={this.props.store.categories[name]}
            data={this.props.store.audience_categories}
            domain={this.props.domain}
            experiment={this.props.experiment}
            normative={this.props.normative}
            expType={this.props.expType}
            normType={this.props.normType}
           />
         </Grid>
        ))}
      </Grid>
    )
  }
}

export default Categorization;