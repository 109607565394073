import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Grid, CircularProgress, Backdrop, Tabs, Tab, Box, Paper, TextField, MenuItem, IconButton } from "@material-ui/core";
import { SaveAlt } from "@material-ui/icons";
import Pagination from '@material-ui/lab/Pagination';
import { withStyles } from "@material-ui/styles";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import Category from "./Category";
import Taxonomy from "./Taxonomy";
import AdminStore from "../../stores/adminstore";


const StyledBackdrop = withStyles({
  root: {
    zIndex: 350000
  }
})(Backdrop);


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

@inject('routing')
@observer
class Admin extends Component {

  constructor(props) {
    super(props);
    this.store = new AdminStore({routing: props.routing})
    this.state = {value: 0}
  }

  componentDidMount() {
    if (isWidthUp('sm', this.props.width)) {
      this.store.itemsPerTaxonomyPage = 10;
    }
  }

  handleChange = (e, v) => {
    this.setState({value: v})
  }

  exportData = e => {
    this.store.exportTaxonomies();
  }

  render() {
    return (
      <Grid container spacing={1}>
        <StyledBackdrop open={this.store.loading} >
          <CircularProgress color="inherit" />
        </StyledBackdrop>
        <Grid item xs={isWidthUp('sm', this.props.width) ? false : 12}>
          <Tabs
            orientation={isWidthUp('sm', this.props.width) ? "vertical" : "horizontal"}
            variant="scrollable"
            value={this.state.value}
            onChange={this.handleChange}
          >
            <Tab label="Interests" />
            <Tab label="Taxonomies" />
          </Tabs>
        </Grid>
        <Grid item xs>
          <TabPanel value={this.state.value} index={0}>
            {this.store.categories.map(cat => (
              <Grid key={cat.category+cat.group} item xs={12}>
                <Paper style={{marginBottom: 8, paddingTop: 16, paddingBottom: 8}}>
                  <Category category={cat.category} hidden={cat.hidden} group={cat.group} store={this.store}/>
                </Paper>
              </Grid>
            ))}
          </TabPanel>
          <TabPanel value={this.state.value} index={1}>
            <Grid item xs container justify={"space-between"}>
              <Grid item xs>
                <TextField
                  select
                  helperText={"Filter specialty categorization"}
                  fullWidth
                  variant={'outlined'}
                  value={this.store.taxonomyItemFilter}
                  onChange={this.store.updateTaxonomyItemFilter}
                >
                  <MenuItem value={'all'}>
                    all
                  </MenuItem>
                  <MenuItem value={'uncategorized'}>
                    uncategorized
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item>
                <IconButton onClick={this.exportData} color={"primary"}><SaveAlt/></IconButton>
              </Grid>
            </Grid>
            {this.store.visibleTaxonomies.map(tax => (
              <Grid item xs={12} key={tax.label+tax.group}>
                <Paper style={{marginBottom: 8, paddingTop: 8, paddingBottom: 4}}>
                  <Taxonomy taxonomy={tax.label} hidden={tax.hidden} group={tax.group} count={tax.count} store={this.store}/>
                </Paper>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Pagination count={this.store.nTaxonomyPages} page={this.store.taxonomyPage} onChange={this.store.setTaxonomyPage} />
            </Grid>
          </TabPanel>
        </Grid>
      </Grid>
    )
  }
}

export default withWidth()(Admin);