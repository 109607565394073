import {
    InputAdornment,
    TextField,
    Typography,
} from '@material-ui/core'
import {
    RemoveRedEye,
    VisibilityOff,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

const PasswordField = (
    {
        classes,
        onChange,
        doLogin,
        initialValue
    },
) => {
    const [password, setPassword] = React.useState(initialValue)
    const [isPasswordVisible, setIsPasswordVisible] = React.useState(false)
    const updatePassword = e => {
        setPassword(e.target.value)
        onChange(e)
    }

    const onKeyPress = e => {
      if (e.key === 'Enter') {
        doLogin()
      }
    }

    return (
        <>
            <Typography className={ classes.fieldLabel }>password</Typography>
            <TextField
                type={ isPasswordVisible ? 'text' : 'password' }
                className={ classes.field }
                value={ password }
                variant="outlined"
                onChange={ updatePassword }
                onKeyPress = { onKeyPress }
                placeholder="Enter Password"
                InputProps={ {
                    endAdornment: (
                        <InputAdornment
                            position="end"
                            className={ classes.eyeHolder }
                        >
                            <RemoveRedEye
                                className={ `${ classes.eye } ${ isPasswordVisible ? '' : 'hide' }` }
                                onClick={ () => setIsPasswordVisible(false) }
                                data-testid="password-visibility-on"
                            />
                            <VisibilityOff
                                className={ `${ classes.eye } ${ isPasswordVisible ? 'hide' : '' } gray` }
                                onClick={ () => setIsPasswordVisible(true) }
                                data-testid="password-visibility-off"
                            />
                        </InputAdornment>
                    ),
                } }
                // eslint-disable-next-line
                inputProps={ {
                    'data-testid': 'password',
                } }
            />
        </>
    )
}

PasswordField.propTypes = {
    classes: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    initialValue: PropTypes.string,
}

export default withStyles(styles)(PasswordField)
