import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Grid } from "@material-ui/core"
import Panel from "./Panel";


@inject('routing')
@observer
class Audience extends Component {

  render() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Panel
            store={this.props.store}
            specs={this.props.specs}
            panel={"experiment"}
          />
        </Grid>
        <Grid item xs={12}>
          <Panel
            store={this.props.store}
            specs={this.props.specs}
            panel={"normative"}
          />
        </Grid>
      </Grid>
    )
  }
}

export default Audience;