import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { observer, inject } from "mobx-react";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import Admin from "./components/Admin";
import NoRouteMatch from "./components/utils/NoRouteMatch";
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    minWidth: 0, // So the Typography noWrap works
    marginLeft: 200
  },
  toolbar: theme.mixins.toolbar,
});

@inject("routing")
@observer
class AppRouter extends Component {

  render() {
    const { classes } = this.props;
    return (
      <Router history={this.props.routing.history}>
        <div className={classes.root}>
          <Switch>
            <Route
              exact
              path="/"
              component={Dashboard}
            />
            <Route
              exact
              path="/admin"
              component={Admin}
            />
            <Route
              exact
              path="/login"
              component={Login}
            />
            <div className={classes.content}>
              <Route component={NoRouteMatch}/>
            </div>
          </Switch>
        </div>
      </Router>
    );
  }
}

export default withStyles(styles)(AppRouter);
