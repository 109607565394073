import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Grid, TextField, Switch } from "@material-ui/core"

@inject('routing')
@observer
class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: props.hidden,
      group: props.group
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.hidden !== this.props.hidden || prevProps.group !== this.props.group) {
      this.setState({
        hidden: this.props.hidden,
        group: this.props.group
      })
    }
  }

  changeGroup = e => {
    this.setState({group: e.target.value})
  }

  updateGroup = e => {
    if (this.state.group !== this.props.group) {
      this.props.store.updateCategory(this.props.category, this.state.group, null);
    }
  }

  updateHidden = e => {
    this.props.store.updateCategory(this.props.category, null, !e.target.checked);
  }

  render() {
    return (
        <Grid container item xs={12} alignItems={"center"} spacing={1} justify={'space-between'}>
          <Grid container item xs={2} md={1} justify={"center"}>
            <Switch
              checked={!this.state.hidden}
              onChange={this.updateHidden}
            />
          </Grid>
          <Grid item xs={10} md={11}>
            <TextField
              size={'small'}
              margin={'dense'}
              variant={'outlined'}
              fullWidth
              label={this.props.category}
              placeholder={"Uncategorized"}
              value={this.state.group}
              onChange={this.changeGroup}
              onBlur={this.updateGroup}
              onKeyDown={(e) => {
                 if (e.key === "Enter") {
                    this.updateGroup()
                 }
              }}
            />
          </Grid>
        </Grid>
    )
  }
}

export default Category;