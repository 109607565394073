import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Grid, LinearProgress, Typography, MenuItem, TextField, Slider, IconButton, FormGroup, FormControlLabel, Switch, Button } from "@material-ui/core"
import { SaveAlt } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import Account from "./Account";
import TopFriendsStore from "../../../stores/topfriendsstore";

const styles = theme => ({
  tableRow: {
    "&:hover": {
      backgroundColor: theme.palette.grey[700]
    }
  },
  tableCell: {
    "&:hover": {
      backgroundColor: theme.palette.grey[700]
    }
  }
});

const MySlider = withStyles({
    valueLabel: {
    '& *': {
      background: 'grey',
      color: '#fff',
    },
  }
})(Slider);

@inject('routing')
@observer
class TopFriends extends Component {
  state = {
    filterElem: false,
    initialThreshold: 0,
    sortOrder: 'desc'
  }

  constructor(props) {
    super(props);
    this.store = new TopFriendsStore({
      routing: props.routing,
      experiment: props.experiment,
      experimentType: props.expType,
      normativeType: props.normType,
      normative: props.normative,
      category: props.category
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let analyze = false;
    if (prevProps.experiment !== this.props.experiment || prevProps.expType !== this.props.expType) {
      this.store.addAudience(this.props.experiment, this.props.expType);
      analyze = true;
    }
    if (prevProps.normative !== this.props.normative || prevProps.normType !== this.props.normType) {
      this.store.addNormative(this.props.normative, this.props.normType);
      analyze = true;
    }
    if (prevProps.category !== this.props.category) {
      this.store.updateCategory(this.props.category);
      analyze = true;
    }
    if (analyze && this.props.autoTrigger) {
      this.store.analyzeAudience();
    } else if (!this.props.autoTrigger && this.props.trigger && prevProps.trigger !== this.props.trigger) {
      this.store.analyzeAudience();
    }
  }

  changeThreshold = e => {
    this.store.updateThreshold(e.target.value);
  }

  setOrderBy = v => {
    this.store.setOrderBy(v)
  }

  toggleOrderDirection = () => {
    this.store.toggleOrderDirection();
  }

  render() {
    const friends = this.store.friends;
    return (
      <Grid container spacing={1} justify={'center'}>
        <Grid container item xs={12} justify={'space-between'} alignItems={'center'} spacing={1}>
          <Grid item>
            <TextField
              select
              margin={"dense"}
              size={"small"}
              variant={"outlined"}
              disabled={this.store.analyzing || this.store.audience.length === 0}
              value={this.store.orderDirection}
              onChange={this.store.setOrderDirection}
              helperText={"sort order"}
            >
              <MenuItem value={"asc"}>Ascending</MenuItem>
              <MenuItem value={"desc"}>Descending</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs>
            <Grid container item xs={12} justify={"center"}>
              <Grid container item justify={"space-between"} alignItems={"center"}>
                <Grid item>
                  <Typography variant={'h6'}>{`Most Followed`}</Typography>
                </Grid>
                <Grid item>
                  <IconButton color={"primary"} disabled={this.store.analyzing || this.store.audience.length === 0} onClick={this.store.exportData}>
                    <SaveAlt/>
                  </IconButton>
                </Grid>
                {this.store.category ?
                  <Button onClick={this.store.fetchLookalikeAccounts}>Lookalike</Button>
                  :
                  <Grid item>
                    <FormGroup row>
                      <FormControlLabel
                        disabled={this.store.analyzing || this.store.audience.length === 0}
                        control={<Switch checked={!this.store.filtered} onChange={this.store.toggleFiltered} name="Everything" />}
                        label="Everything"
                      />
                    </FormGroup>
                  </Grid>
                }
              </Grid>
              <Grid item xs={12}>
                <MySlider
                  disabled={this.store.analyzing || this.store.audience.length === 0}
                  color={"secondary"}
                  onChangeCommitted={this.store.updateThreshold}
                  defaultValue={this.store.thresh}
                  step={1}
                  min={1}
                  max={1000}
                  valueLabelDisplay="auto"
                />
                <Typography variant={"caption"} id="range-slider" gutterTop>
                  {`Minimum Count: ${this.store.thresh}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <TextField
              select
              margin={"dense"}
              size={"small"}
              variant={"outlined"}
              disabled={this.store.analyzing || this.store.audience.length === 0}
              value={this.store.friendOrderBy}
              onChange={this.store.setOrderBy}
              helperText={"order by"}
            >
              <MenuItem value={"count"}>Audience Count</MenuItem>
              <MenuItem value={"chi_norm"}>Specificity</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {this.store.analyzing &&
            <LinearProgress color={"secondary"}/>
          }
        </Grid>
        {friends.map((c, i) => (
          <Grid item xs={12}>
            <Account
              hydrator={this.store.queue}
              profile={c.profile}
              index={i}
              count={c.count}
              chinorm={c.chi_norm}
              chigen={c.chi_genpop}
            />
          </Grid>
        ))}
      </Grid>
    )
  }
}

TopFriends.defaultProps = {autoTrigger: true, trigger: true}

export default withStyles(styles)(TopFriends);