import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withTheme, ThemeProvider } from "@material-ui/core/styles";
import { TextField, MenuItem } from "@material-ui/core"


@inject('routing')
@observer
class SpecialtyPanel extends Component {

  computeTheme = () => {
    const clonedTheme = Object.assign({}, this.props.theme)
    clonedTheme.palette.error = clonedTheme.palette.warning;
    return clonedTheme
  }

  render() {
    return (
      <>
      {this.props.panel === 'experiment' ?
        <ThemeProvider theme={this.computeTheme()}>
          <TextField
            select
            fullWidth
            label={this.props.store.selectedSpecialty ? "Specialty" : "Select Specialty..."}
            variant={'filled'}
            value={this.props.store.selectedSpecialty}
            error={!this.props.store.selectedSpecialty}
            disabled={this.props.store.analyzing}
            onChange={(e) => (this.props.store.selectSpecialty(e.target.value))}
          >
            {this.props.specs.map((option) => (
              <MenuItem key={option.label} value={option.label}>
                {`${option.label} - ${option.count}`}
              </MenuItem>
            ))}
          </TextField>
        </ThemeProvider>
          :
        <TextField
          select
          fullWidth
          label={this.props.store.selectedNormativeSpecialty ? "Specialty" : "Select Specialty..."}
          variant={'filled'}
          value={this.props.store.selectedNormativeSpecialty}
          disabled={this.props.store.analyzing}
          onChange={(e) => (this.props.store.selectNormativeSpecialty(e.target.value))}
        >
          <MenuItem key={'hcps'} value={'allhcps'}>
            All HCPs
          </MenuItem>
          {this.props.specs.map((option) => (
            <MenuItem key={option.label} value={option.label}>
              {`${option.label} - ${option.count}`}
            </MenuItem>
          ))}
        </TextField>
      }
      </>
    )
  }
}

export default withTheme(SpecialtyPanel);