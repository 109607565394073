export default theme => {
    const fieldLabelStyles = {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '15px',
        color: theme.palette.common.white,
        letterSpacing: 1.25,
        textTransform: 'uppercase',
    }
    return (
        {
            nowrap: {
                whiteSpace: 'nowrap',
            },
            main: {
                minHeight: '100vh',
                width: '100vw',
                padding: '0',
                display: 'flex',
                backgroundColor: theme.palette.grey[700],
                paddingBottom: 60,
                [theme.breakpoints.down('xs')]: {
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    paddingBottom: 150,
                    height: '100%',
                },
            },
            content: {
                maxWidth: theme.breakpoints.values.lg,
                alignContent: 'center',
                alignItems: 'center',
                margin: 'auto',
                [theme.breakpoints.down('xs')]: {
                    alignContent: 'start',
                    alignItems: 'start',
                    margin: '0 auto',
                },
            },
            left: {
                position: 'relative',
                maxHeight: '100vh',
                width: '100%',
                [theme.breakpoints.down('xs')]: {
                    paddingBottom: '0 !important',
                },
                '& svg': {
                    width: '100%',
                    '& .st1 g': {
                        display:'none',
                    },
                },
            },
            right: {
                paddingLeft: '0 !important',
                [theme.breakpoints.down('xs')]: {
                    paddingLeft: ` ${ theme.spacing(2) }px !important `,
                },
                '& h3':{
                    marginBottom: theme.spacing(3),
                    color: theme.palette.common.white,
                },
            },
            patternAnim: {
                width: '100%',
            },
            fieldLabel: {
                ...fieldLabelStyles,
                marginBottom: theme.spacing(1),
                marginLeft: theme.spacing(1),
            },
            field: {
                width: '100%',
                marginBottom: theme.spacing(4),
                [theme.breakpoints.down('xs')]: {
                    marginBottom: theme.spacing(3),
                },
                '&& :hover input': {
                    backgroundColor: theme.palette.primary.light,
                },
                '& input':{
                    backgroundColor: theme.palette.grey[800],
                    borderRadius: theme.shape.borderRadius,
                    padding: '16px 5px',
                    border:'4px solid transparent',
                    transition: 'background-color 0.25s',
                    webkitTransition: 'background-color 0.25s',
                    ...theme.typography.subtitle1,
                    '&::placeholder': {
                        ...theme.typography.subtitle1,
                        color: theme.palette.grey[500],
                        fontWeight: theme.typography.fontWeightRegular,
                        opacity: 1,
                    },
                },
                '& .Mui-focused input': {
                    borderColor: theme.palette.primary.light,
                    backgroundColor: `${ theme.palette.grey[600] } !important`,
                },
                '& fieldset':{
                    border: 'none',
                },
            },
            eyeHolder: {
                marginLeft: -38,
            },
            eye: {
                cursor: 'pointer',
                '&&.hide': {
                    fill: theme.palette.text.primary,
                    display: 'none',
                },
                '&&.gray path': {
                    fill: theme.palette.grey[400],
                },
            },
            error: {
                color: theme.palette.error.main,
                marginBottom: 20,
                marginTop: '-25px',
                [theme.breakpoints.down('xs')]: {
                    marginTop: 0,
                },
            },
            loginBtn: {
                cursor: 'pointer',
                fontFamily: theme.typography.fontFamily,
                textTransform: 'uppercase',
                fontSize: 15,
                fontWeight: 700,
                letterSpacing: 1.15,
                lineHeight: '28px',
                padding: '15px 20px',
                width: '100%',
                maxWidth: 160,
                boxShadow: 'none',
                borderRadius: theme.shape.borderRadius,
                backgroundColor: theme.palette.primary.light,
                color:theme.palette.primary.main,
                '&.okta':{
                    maxWidth: '100%',
                    backgroundColor: theme.palette.grey[900],
                    color:theme.palette.primary.main,
                    borderColor: theme.palette.grey[800],
                    borderWidth: 3,
                    transition: 'color 0.25s, border-color 0.25s background-color 0.25s',
                    webkitTransition: 'color 0.25s, border-color 0.25s background-color 0.25s',
                    '&:hover':{
                        borderColor: theme.palette.primary.light,
                        color: theme.palette.primary.main,
                    },
                },
                '&:hover':{
                    backgroundColor: theme.palette.primary.light,
                    opacity: 0.9,
                },
                '&.Mui-disabled':{
                    backgroundColor: theme.palette.grey[800],
                    color: theme.palette.grey[900],
                    opacity: 0.5,
                },
                '& .MuiButton-endIcon svg': {
                    fontSize: 20,
                },
            },
            cb: {
                '& span': {
                    ...fieldLabelStyles,
                },
                '&:hover span':{
                    color: theme.palette.primary.light,
                },
            },
            footer: {
                backgroundColor: theme.palette.grey[700],
                position: 'fixed',
                padding: '16px 0',
                bottom: 0,
                left: 0,
                right: 0,
                borderTop: `1px solid ${ theme.palette.common.white }`,
                color: theme.palette.text.secondary,
                '& .MuiGrid-container': {
                    [theme.breakpoints.down('xs')]: {
                        flexDirection: 'column',
                    },
                },
                '& a, .MuiTypography-body1': {
                    fontFamily: 'Roboto',
                    fontSize: 12,
                    lineHeight: '15px',
                    fontWeight: 400,
                    color: theme.palette.common.white,
                    padding: '6px 14px',
                    marginLeft: theme.spacing(3),
                    transition: 'color 0.25s',
                    webkitTransition: 'color 0.25s',

                    [theme.breakpoints.down('xs')]: {
                        padding: '0px 10px',
                        margin: `${ theme.spacing(0.5) }px ${ theme.spacing(1) }px`,
                    },
                },
                '& .MuiTypography-body1': {
                    marginLeft: 0,
                },
                '& a': {
                    '&:hover': {
                        color: theme.palette.primary.light,
                    },
                },
            },
        }
    )
}
