import { observable, action, computed } from "mobx";
import Simdoc from "../services/simdoc";


export default class AdminStore {
  @observable loading = true;
  @observable taxonomyPage = 1;
  @observable itemsPerTaxonomyPage = 5;
  @observable taxonomyItemFilter = 'all';
  @observable categories = [];
  @observable taxonomies = [];

  constructor(routing) {
    this.routing = routing;
    this.simdoc = new Simdoc(routing);
    this.loadCategories();
    this.loadTaxonomies();
  }

  @action
  loadCategories = () => {
    this.loading = true;
    this.simdoc.listCategoryGroups().then(response => (
      this.categories = response.data.results
    )).then(() => (
      this.loading = false
    )).catch(e => (
      alert(e)
    ))
  }

  @action
  loadTaxonomies = () => {
    this.loading = true;
    this.simdoc.getSpecialties().then(response => (
      this.taxonomies = response.data.results
    )).then(() => (
      this.loading = false
    )).catch(e => (
      alert(e)
    ))
  }

  @action
  updateCategory = (category, group, hidden) => {
    this.loading = true;
    this.simdoc.updateCategoryGroup(category, group, hidden).then(() => (
      this.loadCategories()
    ))
  }

  @action
  updateTaxonomy = (taxonomy, group, hidden) => {
    this.loading = true;
    this.simdoc.updateTaxonomyGroup(taxonomy, group, hidden).then(() => (
      this.loadTaxonomies()
    ))
  }

  @action
  updateTaxonomyItemFilter = (e) => {
    this.taxonomyItemFilter = e.target.value
  }

  @action
  setTaxonomyPage = (e, v) => {
    this.taxonomyPage = v;
  }

  @computed
  get validTaxonomies() {
    switch (this.taxonomyItemFilter) {
      case "all":
        return this.taxonomies
      case 'uncategorized':
        return this.taxonomies.filter(tx => (!Boolean(tx.group)))
      default:
        return this.taxonomies
    }
  }

  @computed
  get visibleTaxonomies() {
    return this.validTaxonomies.slice((this.taxonomyPage-1)*this.itemsPerTaxonomyPage, this.taxonomyPage*this.itemsPerTaxonomyPage)
  }

  @computed
  get nTaxonomyPages() {
    return Math.ceil(this.validTaxonomies.length/this.itemsPerTaxonomyPage)
  }

  exportTaxonomies = () => {
    let csvContent = "data:text/csv;charset=utf-8,";
    const headers = [
      "hidden",
      "mdl",
      "socialgraphics",
      "count"
    ].join(",");
    csvContent += headers + "\r\n";
    this.taxonomies.forEach(function(row) {
      let hidden = row.hidden ? true : false;
      let group = row.group ? row.group : "";
        let csvRow = [
          hidden,
          `"${row.label}"`,
          `"${group}"`,
          row.count
        ].join(",");
        csvContent += csvRow + "\r\n";
    });
    const encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "taxonomy_map.csv");
    document.body.appendChild(link); // Required for FF
    link.click();
  }
}