import React, { Component } from "react";
import { inject } from "mobx-react";
import SVG from 'react-inlinesvg';
import { gsap } from "gsap";
import {
    Box,
    Container,
    Grid,
    Hidden,
    Typography,
    TextField,
    Button,
    CircularProgress,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';

import styles from './styles'

import PasswordField from './password-field'

@inject('appstore')
@inject('routing')
class Login extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	    	username: '',
	    	password: '',
			  isProcessing: props.isProcessing ? props.isProcessing : false,
			  loginFailed: false
	    };

	    //methods
	    this.setUsername = this.setUsername.bind(this);
	    this.setPassword = this.setPassword.bind(this);
	    this.animCode = this.animCode.bind(this);
	}

	setUsername(value) {
		this.setState({username: value})
	}

	setPassword(value) {
		this.setState({password: value})
	}

	animCode(mobile) {
        const gridCount = mobile ? [8, 5] : [8, 9]
        const svg = document.getElementById(mobile ? 'animMobile' : 'anim')
        const xGroup = svg.querySelectorAll('.st1 g')
        const xGroupH = svg.querySelectorAll('.st1 g .st2:first-child')
        const xGroupV = svg.querySelectorAll('.st1 g .st2:last-child')
        const scaleAxisY = mobile ? 'scaleX' : 'scaleY'
        const scaleAxisX = mobile ? 'scaleY' : 'scaleX'

        const tl = gsap.timeline({ onComplete:function() { tl.seek('loopHere') } })
        tl.timeScale(4)
        gsap.set(xGroup, {
            rotation: 45,
            transformOrigin: '50% 50%',
            display: 'block',
        })
        tl
            .from(xGroupH, {
                duration: 6,
                transformOrigin: '50% 50%',
                scaleX: 0,
                ease: 'sine.in',
                stagger: {
                    grid: gridCount,
                    from: 'end',
                    amount: 8,
                },
            })
            .from(xGroupV, {
                duration: 6,
                transformOrigin: '50% 50%',
                //
                [scaleAxisX]: 0,
                ease: 'sine.in',
                stagger: {
                    grid: gridCount,
                    from: 'end',
                    amount: 8,
                },
            }, '-=14')
            .addLabel('loopHere', '-=1')
            .to(xGroupH, {
                duration: 1,
                transformOrigin: '50% 50%',
                scaleY: 1.8,
                ease: 'none',
                stagger: {
                    grid: gridCount,
                    from: 'start',
                    amount: 8,
                },
            }, '-=2')
            .to(xGroupV, {
                duration: 1,
                transformOrigin: '50% 50%',
                //
                [scaleAxisY]: 1.56,
                ease: 'none',
                stagger: {
                    grid: gridCount,
                    from: 'start',
                    amount: 8,
                },
            }, '-=9')
            .to(xGroupH, {
                duration: 1,
                transformOrigin: '50% 50%',
                scaleY: 1,
                ease: 'none',
                stagger: {
                    grid: gridCount,
                    from: 'start',
                    amount: 8,
                },
            }, '-=5')
            .to(xGroupV, {
                duration: 1,
                transformOrigin: '50% 50%',
                //
                [scaleAxisY]: 1,
                ease: 'none',
                stagger: {
                    grid: gridCount,
                    from: 'start',
                    amount: 8,
                },
            }, '-=9')
    }

	doLogin = () => {
		this.setState({isProcessing: true, loginFailed: false});
		this.props.appstore.login(this.state.username, this.state.password).then(() => {
			this.props.appstore.authenticate()
			this.setState({isProcessing: false, loginFailed: false})

            const { username, password, isChecked } = this.state
            if (isChecked && username !== "") {
                localStorage.username = username
                localStorage.password = password
                localStorage.checkbox = isChecked
            }
		}).catch((e) => {
		  console.log(e)
			this.setState({isProcessing: false, loginFailed: true})
		})
	}
    doLoginOkta = () => {
        //TODO:set up OKTA log
    }
    rememberMeOnChange = (e) => {
        this.setState({
            isChecked: e.target.checked
        })
    }

    componentDidMount() {
        if (localStorage.checkbox && localStorage.username !== "" && localStorage.password !== "") {
            this.setState({
                isChecked: true,
                username: localStorage.username,
                password: localStorage.password
            })
        }
    }

	render() {
		const username = this.state.username;
		const password = this.state.password;
		const isProcessing = this.state.isProcessing;
		const loginFailed = this.state.loginFailed;
		const classes = this.props.classes;
		const isValid = username && password

    return (
       <form onSubmit={ this.props.submit } action="#">
            <Box>
                <Container maxWidth={ false } className={ classes.main }>
                    <Grid container direction="row" m="auto" spacing={ 4 } className={ classes.content }>
                        <Hidden xsDown>
                            <Grid item xs={ 12 } sm={ 7 } className={ classes.left }>
                                <SVG uniquifyIDs={ false } cacheRequests={ false }
                                    src={ require('./svgs/loginAnimation.svg') } onLoad={ () => {
                                        this.animCode(false)
                                    } }
                                />
                            </Grid>
                        </Hidden>
                        <Hidden smUp>
                            <Grid item xs={ 12 } className={ classes.left }>
                                <SVG uniquifyIDs={ false } cacheRequests={ false }
                                    src={ require('./svgs/loginAnimationMobile.svg') } onLoad={ () => {
                                        this.animCode(true)
                                    } }
                                />
                            </Grid>
                        </Hidden>
                        <Hidden smDown>
                            <Grid item sm={ 1 } md={ 1 } />
                        </Hidden>
                        <Grid item xs={ 12 } sm={ 5 } md={ 4 } className={ classes.right }>
                            <Typography variant={ 'h3' }>Socialgraphics</Typography>
                            <Grid item>
                                <Typography className={ classes.fieldLabel }>username</Typography>
                                <TextField
                                    autoFocus
                                    className={ classes.field }
                                    value={ username }
                                    variant="outlined"
                                    onChange={ e => this.setUsername(e.target.value) }
                                    placeholder="Enter Username"
                                    inputProps={ {
                                        'data-testid': 'username',
                                    } }
                                />
                            </Grid>
                            <Grid item>
                                <PasswordField doLogin={this.doLogin} initialValue={ this.state.password } onChange={ e => this.setPassword(e.target.value) } />
                            </Grid>
                            {
                                loginFailed &&
                                <Grid>
                                    <Typography className={ classes.error } data-testid="login-failure-message">
                                        Login Failed. <span className={ classes.nowrap }
                                        >Check username/password.</span>
                                    </Typography>
                                </Grid>
                            }
                            <Grid container direction="row" alignItems="center" justify="space-between">
                                <Grid item xs={ 6 }>
                                </Grid>
                                <Grid item container direction="column" alignItems="flex-end" xs={ 6 } >
                                    <Button
                                        className={ classes.loginBtn }
                                        variant="contained"
                                        color="primary"
                                        onClick={this.doLogin}
                                        disabled={ !isValid || isProcessing }
                                        endIcon={ isProcessing ? <CircularProgress size="30px"
                                            data-testid="spinner"
                                        /> : '' }
                                    >
                                      Log In
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
                <Container maxWidth={ false } className={ classes.footer }>
                    <Container maxWidth="lg">
                        <Grid container direction="row" alignItems="center" justify="space-between">
                            <Grid item>
                                <Typography>W2O &copy; 2021</Typography>
                            </Grid>
                            <Grid item>
                                <Button href="/help">Help</Button>
                                <Button href="/privacy">Privacy</Button>
                                <Button href="/terms">Terms</Button>
                            </Grid>
                        </Grid>
                    </Container>
                </Container>
            </Box>
        </form>
	    )
  	}
}

Login.defaultProps = {
    classes: {},
}

export default withStyles(styles)(Login);
