import React, { Component } from "react";
import { observer } from "mobx-react";
import { Grid, Typography } from '@material-ui/core';

export default @observer class NoRouteMatch extends Component {
  render() {
    return (
      <Grid
        container
        direction="row"
        spacing={1}
        style={{paddingTop: '70px'}}
      >
        <Grid item xs={12} style={{width: '100%', overflowY: "scroll"}}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            alignContent="center"
            style={{ paddingTop: "70px", height: "100%" }}
          >
            <Grid container direction="column" item justify="center">
              <Grid container item justify="center">
                <Typography variant='h5'>404</Typography>
              </Grid>
              <Grid container item justify="center">
                <Typography variant='h6'>Page Not Found</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
