import { v4 as uuidv4 } from "uuid";


export default class BatchProcessingQueue {
  queue = {};
  timeout = null;

  constructor({batchExecuteFunction, batchSize=100, maxEventLoopDurationSeconds=1}={}) {
    this.batchExecuteFunction = batchExecuteFunction;
    this.batchSize = batchSize;
    this.maxEventLoopDurationSeconds = maxEventLoopDurationSeconds;
  }

  add = item => {
    const promise = this.enqueue(item);
    this.evaluateSituation();
    return promise
  }

  async evaluateSituation() {
    this.timeout && clearTimeout(this.timeout);
    if (Object.keys(this.queue).length >= this.batchSize) {
      this.doBatchProcess()
    } else {
      this.timeout = setTimeout(this.doBatchProcess, this.maxEventLoopDurationSeconds*1000)
    }
  }

  enqueue = item => {
    let resolver, rejector;
    const promise = new Promise((resolve, reject) => {
      resolver = resolve;
      rejector = reject
    })
    const itemId = uuidv4();
    this.queue[itemId] = {
      "resolve": resolver,
      "reject": rejector,
      "item": item,
      "pending": false,
      "id": itemId
    }
    return promise;
  }

  pop = (obj, key) => {
    let tmp = obj[key];
    delete obj[key];
    return tmp
  }

  readyItems = (maxN) => {
    const items = [];
    for (const key of Object.keys(this.queue)) {
      let item = this.queue[key];
      if (!item.pending) {
        items.push(item)
      }
    }
    return items.slice(0, maxN);
  }

  doBatchProcess = () => {
    const promiseHandlerMap = {};
    const itemsToProcess = [];
    const ready = this.readyItems(this.batchSize);
    for (const item  of ready) {
      item["pending"] = true;
      promiseHandlerMap[item.id] = item;
      itemsToProcess.push({
        id: item.id,
        item: item.item
      });
    }
    this.batchExecuteFunction(itemsToProcess).then(results => {
      for (const result of results) {
        if (result.id && result.data) {
          const handlers = this.pop(promiseHandlerMap, result.id);
          handlers.resolve(result.data);
          this.pop(this.queue, result.id);
        }
      }
    }).then(() => (this.rejectAll(promiseHandlerMap)))
      .catch(() => (this.rejectAll(promiseHandlerMap)))
  }

  rejectAll = map => {
    for (const key of Object.keys(map)) {
      const item = map[key];
      map[key]["reject"](item);
      this.pop(this.queue, key);
    }
  }
}