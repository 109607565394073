import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import AppRouter from "../../AppRouter";
import AppBar from "../AppBar";

@inject('routing')
@observer
class Home extends Component {
  render() {
    return (
      <div>
        <div hidden={this.props.routing.location.pathname==='/login'}>
        	<AppBar />
        </div>
        <AppRouter />
      </div>
    )
  }
}

export default Home;