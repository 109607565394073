import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Grid, TextField, Button, CircularProgress, Typography } from "@material-ui/core";

@inject('routing')
@observer
class CustomPanel extends Component {

  constructor(props) {
    super(props);
    this.store = props.store.customPanelStores[props.panel];
  }

  analyzeNPIs = () => {
    this.props.store.addNPIs(this.store.validation.parsed, this.props.panel);
  }

  render() {
    const userInput = this.store.userInput;
    return (
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12}>
          <TextField
            value={userInput}
            onChange={this.store.modifyUserInput}
            fullWidth
            multiline
            color={"secondary"}
            variant={"outlined"}
            label={"NPI Numbers"}
            helperText={"paste one per line"}
            rows={2}
            rowsMax={12}
          />
        </Grid>
        {this.store.validated &&
          <Grid container item xs={12} justify={"space-between"} spacing={1}>
            <Grid item>
              <Typography variant={"body2"}>Interpreted:</Typography>
              <Typography variant={"body2"}>{this.store.validation.parsed.length}</Typography>
            </Grid>
            <Grid item>
              <Typography variant={"body2"}>Unmatched:</Typography>
              <Typography color={"error"} variant={"body2"}>{this.store.validation.bad}</Typography>
            </Grid>
            <Grid item>
              <Typography variant={"body2"}>Matched:</Typography>
              <Typography color={"primary"} variant={"body2"}>{this.store.validation.good}</Typography>
            </Grid>
          </Grid>
        }
        <Grid item container xs={12} justify={"flex-end"} spacing={1}>
          {this.store.validated ?
            <Button
              variant={"contained"}
              color={"primary"}
              disabled={this.store.validation.good ? this.store.validation.good === 0 : true}
              onClick={this.analyzeNPIs}
            >
              {`Analyze ${this.store.validation.good} Matched`}
            </Button>
            :
            <Button
              disabled={userInput ? userInput.length === 0 : true}
              onClick={this.store.validate}
              startIcon={this.store.validating ? <CircularProgress color={"inherit"} size={20}/> : null}
            >
              Validate
            </Button>
          }
        </Grid>
      </Grid>
    )
  }
}

export default CustomPanel;
