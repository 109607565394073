import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Grid, Typography, Paper, Divider} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
});

function nFormatter(num) {
     if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
     }
     if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
     }
     if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
     }
     if (num >= 1) {
        if (Number.isInteger(num)) {
          return num.toFixed(0)
        } else {
          return num.toFixed(1);
        }
     }
     if (num >= 0.01) {
       return num.toFixed(2);
     }
     return num.toFixed(3);
}


@inject('routing')
@observer
class Affinity extends Component {

  render() {
    const {data, count, chinorm} = this.props;
    return (
      <Paper style={{padding: 4, backgroundColor: "#2f3640"}}>
        <Grid container item xs={12} alignItems={'center'} justify={"space-between"}>
          <Grid container item xs justify={'center'} direction={'column'} alignItems={'center'} spacing={1}>
            <Grid item>
              <Typography variant={'caption'}>{data.name}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography variant={"caption"}>
              {data.path}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider light/>
          </Grid>
          <Grid container item xs={12} justify={"space-between"} alignItems={'center'} direction={"row"}>
            <Grid container item xs alignItems={"center"}>
              <Grid item>
                <Typography variant={"caption"}>{data.affinity_id}</Typography>
              </Grid>
            </Grid>
            <Grid item xs>
              <span>
                <Typography style={{paddingRight: 4}} variant={"caption"}>Audience Count:</Typography>
                <Typography style={{fontWeight: 'bold'}} variant={"caption"}>{nFormatter(count)}</Typography>
              </span>
              <span style={{paddingLeft: 8}}>
                <Typography style={{paddingRight: 4}} variant={"caption"}>Specificity:</Typography>
                <Typography style={{fontWeight: 'bold'}} variant={"caption"}>{nFormatter(chinorm)}</Typography>
              </span>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

export default withStyles(styles)(Affinity);