import { observable, action, computed, runInAction } from "mobx";
import Gemini from "../services/gemini";

const ADMINS = [
  'jbyrne',
  'sduncan',
  'mbooth',
  'aboothe',
  'dsteffen',
  'hbrashear'
]

export default class AppStore {
  @observable authenticated = false;
  @observable adminOverride = false;
  @observable
  user = {
    lastName: "",
    email: "",
    firstName: "",
    id: ""
  };
  @observable
  fontconfig = {
    google: {
        families: ['Source Sans Pro:400,600,700,900', 'sans-serif', 'Montserrat:300,400,500,600,700', 'sans-serif'],
    }
  };

  constructor(routing, history) {
    this.routing = routing;
    this.gemini = new Gemini({routing: routing});
    this.history = history;
    this.updateWindowDimensions();
    if (window.addEventListener) {
      window.addEventListener("resize", this.updateWindowDimensions);
      let keys = [],
          konami = "38,38,40,40,37,39,37,39,66,65";

      // bind the keydown event to the Konami function
      window.addEventListener("keydown", function(e) {
          // push the keycode to the 'keys' array
          keys.push(e.keyCode);

          // and check to see if the user has entered the Konami code
          if (keys.toString().indexOf(konami) >= 0) {
              console.log("admin");
              runInAction(() => {
                this.makeAdmin();
              });
              // and finally clean up the keys array
              keys = [];
          }
        }.bind(this), true)
      }
    }

  @action
  makeAdmin() {
    this.adminOverride = true;
  }

  @computed
  get admin() {
    if (this.adminOverride) {
      return true
    }
    if (ADMINS.includes(this.user.id)) {
      return true;
    }
    return false;
  }

  @action
  async init() {
    await Promise.all([
      this.authenticate(),
    ]);
  }

  @action
  authenticate = async () => {
    const user = await this.gemini.getUserInfo();
    if (user) {
      this.user = user.data;
    }
  }

  @action.bound
  updateWindowDimensions() {
    runInAction(() => {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    });
  }

  @action
  async login(username, password) {
    return this.gemini.login(username, password)
  }

  @action
  logout = () => {
    this.gemini.logout();
  }
}