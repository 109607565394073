import React, { Component } from "react";
import { observer } from "mobx-react";
import { Grid, Typography, Paper, ButtonBase, LinearProgress } from "@material-ui/core";

@observer
class AccountCategorization extends Component {
  render() {
    const {accountCategorization, categorizationLoading} = this.props.store;
    return (
      <Grid item xs={12} container alignItems={"center"} justify={"center"} alignContent={"center"} spacing={1}>
        {categorizationLoading &&
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        }
        {Object.keys(accountCategorization).map((key) => (
          <Grid container item xs={4} justify={"center"} alignItems={"center"}>
            <ButtonBase style={{width: '100%', height: '100%'}} disabled={this.props.store.categorizationLoading} fullWidth onClick={() => (accountCategorization[key] > 0 ? this.props.store.uncategorize(key) : this.props.store.categorize(key))}>
              <Paper style={{backgroundColor: accountCategorization[key] > 0 ? '#2ecc71': '#7f8c8d', width: '100%', height: '100%'}}>
                <Typography variant={"caption"}>{key}</Typography>
              </Paper>
            </ButtonBase>
          </Grid>
        ))}
      </Grid>
    )
  }
}

export default AccountCategorization;