import { observable, action } from "mobx";
import Simdoc from "../services/simdoc";


export default class CustomPanelStore {
  @observable validating = false;
  @observable validated = false;
  @observable validation = {"good": 0, "bad": 0, "parsed": []}
  @observable userInput = '';

  constructor(routing) {
    this.routing = routing;
    this.simdoc = new Simdoc(routing);
  }

  @action
  modifyUserInput = e => {
    this.validated = false;
    this.userInput = e.target.value;
  }

  @action.bound
  async validate() {
    this.validating = true
    try {
      const response = await this.simdoc.validateNPIs(this.userInput)
      if (response) {
        this.validation = response.data
        this.validated = true
        this.validating = false
      }
    } catch (e) {
      this.validated = false
      this.validating = false
    }
  }

}