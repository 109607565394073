import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Grid, TextField, Switch, MenuItem } from "@material-ui/core"

const OPTIONS = [
  "Allergy & Immunology",
  "Anesthesiology",
  "Cardiology",
  "Combined Specialty",
  "Dermatology",
  "Dentistry", // Addition to AMA
  "Emergency Medicine",
  "Endocrinology, Diabetes, & Metabolism",
  "Family Medicine",
  "Gastroenterology",
  "General Practice",
  "Infectious Disease",
  "Internal Medicine",
  "Medical Genetics",
  "Nephrology",
  "Neurological Surgery",
  "Neurology",
  "Nursing", // Addition to AMA
  "Obstetrics & Gynecology",
  "Oncology (Cancer)",
  "Ophthalmology",
  "Orthopedics",
  "Other",
  "Otolaryngology",
  "Pathology",
  "Pediatrics",
  "Pharmacist", // Addition to AMA
  "Physical Medicine & Rehabilitation",
  "Physician Assistant", // Addition to AMA
  "Plastic Surgery",
  "Preventive Medicine",
  "Psychiatry",
  "Radiology",
  "Social Work & Counseling", // Addition to AMA
  "Student", // Addition to AMA
  "Surgery",
  "Urology"
]

@inject('routing')
@observer
class Taxonomy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: props.hidden,
      group: props.group
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.hidden !== this.props.hidden || prevProps.group !== this.props.group) {
      this.setState({
        hidden: this.props.hidden,
        group: this.props.group
      })
    }
  }

  changeGroup = e => {
    this.setState({group: e.target.value})
  }

  updateGroup = e => {
    this.setState({group: e.target.value}, () => (
      this.props.store.updateTaxonomy(this.props.taxonomy, this.state.group, null)
    ))
  }

  updateHidden = e => {
    this.props.store.updateTaxonomy(this.props.taxonomy, null, !e.target.checked);
  }

  render() {
    return (
      <Grid container item xs={12} alignItems={"center"} spacing={1} justify={'space-between'}>
        <Grid container item xs={2} md={1} justify={"center"}>
          <Switch
            checked={!this.state.hidden}
            onChange={this.updateHidden}
          />
        </Grid>
        <Grid item xs={10} md={11}>
          <TextField
            select
            size={'small'}
            margin={'dense'}
            error={this.state.group ? false : true}
            label={this.props.taxonomy}
            helperText={this.props.count}
            fullWidth
            variant={'outlined'}
            placeholder={"Uncategorized"}
            value={this.state.group}
            onChange={this.updateGroup}
          >
            {OPTIONS.map((option) => (
              <MenuItem key={option} value={option}>
                {`${option}`}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    )
  }
}

export default Taxonomy;