import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import CategoryResult from "./CategoryResult";


const styles = theme => ({
  content: {
    [theme.breakpoints.up('md')]: {
      maxHeight: '500px',
      overflowY: 'scroll'
    }
  }
})


@inject('routing')
@observer
class CategoryResults extends Component {
  render() {
    const classes = this.props.classes;
    return (
      <div>
        <Grid item xs={12} container justify={"center"} spacing={1}>
          <Grid item>
            <Typography variant={"h6"}>{`${this.props.name} Interests`}</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justify={"center"}
          spacing={1}
          className={classes.content}
        >
          <Grid
           container
           direction={'row'}
           item
           xs={12}
           justify={'flex-start'}
           alignItems={'stretch'}
           alignContent={'stretch'}
           spacing={1}
          >
            {this.props.data.filter(row => (this.props.labels.includes(row.category))).sort((a, b) => (b.score - a.score)).map((c) => (
              <Grid item xs={12} key={`${c.category} - ${this.props.store.selectedSpecialty} - ${this.props.store.selectedNormativeSpecialty}`}>
                <CategoryResult
                  c={c}
                  analyzing={this.props.store.analyzing}
                  domain={this.props.domain}
                  experiment={this.props.experiment}
                  normative={this.props.normative}
                  expType={this.props.expType}
                  normType={this.props.normType}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(CategoryResults);