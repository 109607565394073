import React, { Component, Fragment } from 'react';
import { observer, inject } from "mobx-react";
import { Typography } from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { MultiBar } from '@w2ogroup/components';

const styles = theme => ({
  badge: {
      width: '80px',
      borderRadius: '10px',
      color: 'white',
      marginTop: 2
    }
});


@inject('routing')
@inject('appstore')
@observer
class AppBar extends Component {

  makeName = () => {
    return (
      <Typography
        variant="h6"
        component="span"
        color="inherit"
        style={{cursor: 'pointer'}}
        onClick={() => {this.props.routing.push('/')}}
      >
        HCP Socialgraphics
      </Typography>
    )
  }

  visitAdmin = e => {
    this.props.routing.push('/admin')
  }

  render() {
    let avatar = false;
    let username = false;
    if (this.props.appstore.user.id) {
      avatar = `https://rcdais.com/`
      username = this.props.appstore.user.id;
    }
    const barChildren = [];
    if (this.props.appstore.admin) {
      barChildren.push(
        <Settings onClick={this.visitAdmin} style={{marginTop: '8px', cursor: "pointer"}} align={"right"}/>
      )
    }

    return (
      <Fragment>
        <MultiBar
          name={this.makeName()}
          authenticated={true}
          username={username}
          avatar={avatar}
          children={barChildren}
          onLogout={this.props.appstore.logout}
        />
      </Fragment>
    )
  }
}

export default withStyles(styles)(AppBar)