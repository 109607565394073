import React, {Component} from "react";
import {observer, inject} from "mobx-react";
import { Grid, Paper, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Typography} from "@material-ui/core";
import { grey } from '@material-ui/core/colors';
import { withStyles } from "@material-ui/styles";
import SpecialtyPanel from "./SpecialtyPanel";
import CustomPanel from "./CustomPanel";

const CoolRadio = withStyles({
  root: {
    color: grey[400],
    '&$checked': {
      color: grey[100],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

@inject('routing')
@observer
class Panel extends Component {

  handleChange = e => {
    this.props.store.setSelectionType(e.target.value, this.props.panel);
  }

  getPanelChooser = selection => {
    switch (selection) {
      case "specialty":
        return SpecialtyPanel
      case "upload":
        return CustomPanel
      default:
        return SpecialtyPanel
    }
  }

  render() {
    const selectionType = this.props.store.selectionType[this.props.panel];
    const PanelChooser = this.getPanelChooser(selectionType);
    return (
      <Paper variant="outlined" square style={{paddingBottom: 8}}>
        <Grid container item xs={12} spacing={1} style={{paddingLeft: 8, paddingRight: 8}}>
          <Grid item xs={12}>
            <FormControl component="fieldset" margin={"dense"} size={"small"}>
              <FormLabel color="secondary" style={{textTransform: "capitalize"}} component="legend">{`${this.props.panel} Panel`}</FormLabel>
              <RadioGroup value={selectionType} onChange={this.handleChange} row>
                <FormControlLabel value="specialty" control={<CoolRadio />} label={<Typography variant={"caption"}>Choose By Specialty</Typography>} />
                <FormControlLabel value="upload" control={<CoolRadio />} label={<Typography variant={"caption"}>Upload NPI's</Typography>} />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <PanelChooser
              store={this.props.store}
              specs={this.props.specs}
              panel={this.props.panel}
            />
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

export default Panel;
