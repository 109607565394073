import { createMuiTheme } from '@material-ui/core/styles'
import WebFont from 'webfontloader'

WebFont.load({
    google: {
        families: ['Nunito Sans:300,400,500,600,800', 'sans-serif', 'Roboto:400,500,700', 'sans-serif'],
    },
    custom: {
        families: ['Helvetica Now Text:n3,n4,n5,n6,n7'],
        urls: [`/fonts/HelveticaNow/stylesheet.css`],
    },
})

export const getDefaultTheme = () => {
    const theme = createMuiTheme({
        themeName: 'Global Theme',
        spacing: 8,
        breakpoints: {
            keys: [
                'xxs',
                'xs',
                'sm',
                'md',
                'lg',
                'xl',
            ],
            values: {
                xl: 1920,
                lg: 1280,
                md: 1024,
                sm: 769,
                xs: 375,
                xxs: 0,
            },
        },
        palette: {
            type: 'dark',
            // background: {
            //     default: '#FBFEFF',
            //     paper: '#FFFFFF',
            // },
            common: {
                black: '#01121A',
                black90: '#01121AE5',
                black75: '#01121ABF',
            },
            primary: {
                contrastText: '#212121',
                light: '#b6ffff',
                main: '#81d4fa',
                dark: '#4ba3c7',
            },
            secondary: {
                main: '#F15922',
            },
            grey: {
                25: '#FBFEFF',
                50: '#F3F6F8',
                100: '#e4ecf1',
                200: '#d6dfe3',
                300: '#c2cbcf',
                400: '#9da5a9',
                500: '#7c8387',
                600: '#555c60',
                700: '#434a4e',
                800: '#262c30',
                900: '#000b10',
            },
            error: {
                main: '#FC3C3C',
            },
            // text: {
            //     primary: '#01121A',
            //     primary90: '#01121AE5',
            //     primary75: '#01121ABF',
            //     secondary: '#000000',
            // },
        },
        shape: {
            borderRadius: 8,
        },
        typography: {
            fontFamily: 'Helvetica Now Text',
            fontWeightLight: 300,
            fontWeightRegular: 400,
            fontWeightMedium: 500,
            fontWeightSemiBold: 500,
            fontWeightBold: 700,
            fontWeightBlack: 800,
        },
        overrides: {},
    })

    theme.typography.h1 = {
        fontFamily: theme.typography.fontFamily,
        fontSize: 96,
        letterSpacing: -1.5,
        fontWeight: theme.typography.fontWeightLight,
        lineHeight: '116px',
        color: theme.palette.text.primary,
    }

    theme.typography.h2 = {
        fontFamily: theme.typography.fontFamily,
        fontSize: 60,
        letterSpacing: -0.5,
        fontWeight: theme.typography.fontWeightLight,
        lineHeight: '73px',
        color: theme.palette.text.primary,
        [theme.breakpoints.down('sm')]: {
            fontSize: 48,
            lineHeight: '60px',
        },
    }

    theme.typography.h3 = {
        fontFamily: theme.typography.fontFamily,
        fontSize: 48,
        letterSpacing: '0',
        fontWeight: theme.typography.fontWeightSemiBold,
        lineHeight: '59px',
        color: theme.palette.text.primary,
        [theme.breakpoints.down('sm')]: {
            fontSize: 38,
            lineHeight: '40px',
        },
    }

    theme.typography.h4 = {
        fontFamily: theme.typography.fontFamily,
        fontSize: 34,
        letterSpacing: 0.25,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '41px',
        color: theme.palette.text.primary,
    }

    theme.typography.h5 = {
        fontFamily: theme.typography.fontFamily,
        fontSize: 24,
        letterSpacing: 0,
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight: '29px',
        color: theme.palette.text.primary,
    }

    theme.typography.h6 = {
        fontFamily: theme.typography.fontFamily,
        fontSize: 20,
        letterSpacing: 0.15,
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: '28px',
        color: theme.palette.text.primary,
    }

    theme.typography.subtitle1 = {
        fontFamily: theme.typography.fontFamily,
        fontSize: 16,
        letterSpacing: 0.15,
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight: '24px',
        color: theme.palette.text.primary,
    }

    theme.typography.subtitle2 = {
        fontFamily: theme.typography.fontFamily,
        fontSize: 14,
        letterSpacing: 0.1,
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: '24px',
        color: theme.palette.text.primary,
    }

    theme.typography.body1 = {
        fontFamily: 'Roboto',
        fontSize: 17,
        letterSpacing: 0.5,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '23px',
        color: theme.palette.text.primary,
    }

    theme.typography.body2 = {
        fontFamily: 'Roboto',
        fontSize: 15,
        letterSpacing: 0.5,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '19px',
        color: theme.palette.text.primary,
    }

    theme.typography.button = {
        fontFamily: 'Roboto',
        fontSize: 15,
        letterSpacing: 1.25,
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: '16px',
        textTransform: 'uppercase',
        color: theme.palette.text.primary,
    }

    theme.typography.overline = {
        fontFamily: 'Roboto',
        fontSize: 10,
        letterSpacing: 1.5,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '16px',
        textTransform: 'uppercase',
        color: theme.palette.text.primary,
    }

    theme.typography.overline2 = {
        fontFamily: 'Roboto',
        fontSize: 12,
        letterSpacing: '0.2em',
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '16px',
        color: theme.palette.text.primary,
    }

    theme.typography.caption = {
        fontFamily: 'Roboto',
        fontSize: 13,
        letterSpacing: 0.2,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '16px',
        color: theme.palette.text.primary,
    }

    theme.typography.customVariant1 = {
        fontFamily: 'Roboto',
        fontSize: 14,
        letterSpacing: 0.563218,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '15px',
        color: theme.palette.text.primary75,
    }

    theme.typography.customVariant2 = {
        fontFamily: 'Roboto',
        fontSize: 20,
        letterSpacing: 0,
        fontWeight: theme.typography.fontWeightBlack,
        lineHeight: '22px',
        color: theme.palette.text.primary90,
    }

    theme.mixins.noWrap = {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    }

    theme.mixins.ellipsis = {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    }

    theme.mixins.breakWord = {
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        msWordBreak: 'break-all',
        wordBreak: 'break-word',
    }

    theme.overrides.MuiChip = {
        root: {
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
            backgroundColor: theme.palette.primary.light50,
            padding: '4px 12px',
            fontFamily: 'Source Sans Pro',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            '& span': {
                ...theme.typography.body2,
            },
            '&&.active': {
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: theme.palette.primary.light,
                },
            },
        },
    }

    theme.overrides.MuiToggleButtonGroup = {
        root: {
            '& button': {
                webkitBorderRadius: theme.shape.borderRadius * 1,
                borderRadius: theme.shape.borderRadius * 1,
                border: 'none',
                padding: '4px 24px',
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.grey[50],
                '&.Mui-selected': {
                    backgroundColor: theme.palette.primary.light50,
                },
            },
        },
    }

    theme.overrides.MuiTable = {
        root: {
            border: `1px solid ${ theme.palette.grey[200] }`,
            webkitBorderRadius: '10px 10px 0 0',
            borderRadius: '10px 10px 0 0',
            borderCollapse: 'separate',
        },
    }

    theme.overrides.MuiTableHead = {
        root: {
            '& tr:first-child': {
                backgroundColor: theme.palette.grey['50'],
                '& th:first-child': {
                    webkitBorderRadius: '10px 0 0 0',
                    borderRadius: '10px 0 0 0',
                },
                '& th:last-child': {
                    webkitBorderRadius: '0 10px 0 0',
                    borderRadius: '0 10px 0 0',
                },
                '& th:only-child': {
                    webkitBorderRadius: '10px 10px 0 0',
                    borderRadius: '10px 10px 0 0',
                },
            },
            '& tr:last-child': {
                '& th': {
                    padding: '12px 10px 12px 7px',
                    backgroundColor: 'transparent',
                    lineHeight: 'normal',
                    borderBottom: `1px solid ${ theme.palette.grey[200] }`,
                    verticalAlign: 'bottom',
                },
            },
        },
    }

    theme.overrides.MuiTableBody = {
        root: {
            '& tr:hover': {

                '& td:before': {
                    opacity: 1,
                },

                '& td .profileIcon': {
                    opacity: 1,
                },
            },
            '& td': {
                padding: '12px 10px 12px 7px',
                position: 'relative',
                cursor: 'pointer',
                borderBottom: `1px solid ${ theme.palette.grey[200] }`,
                '& span': {
                    ...theme.mixins.ellipsis,
                    zIndex: 1,
                    position: 'relative',
                    display: 'block',
                },
                '&:before': {
                    content: '""',
                    position: 'absolute',
                    width: '100%',
                    height: 'calc(100% - 10px)',
                    left: 0,
                    top: 0,
                    backgroundColor: theme.palette.grey[50],
                    borderTop: '5px solid white',
                    borderBottom: '5px solid white',
                    transition: `opacity ${ theme.transitions.duration.short / 1000 }s`,
                    webkitTransition: `opacity ${ theme.transitions.duration.short / 1000 }s`,
                    opacity: 0,
                },
                '&:first-child:before': {
                    borderLeft: '5px solid white',
                    webkitBorderRadius: '30px 0 0 30px',
                    borderRadius: '30px 0 0 30px',
                },
                '&:last-child:before': {
                    borderRight: '5px solid white',
                    webkitBorderRadius: '0 30px 30px 0',
                    borderRadius: '0 30px 30px 0',
                    left: 'auto',
                    right: 0,
                },
            },

        },
    }

    theme.overrides.MuiRadio = {
        colorSecondary: {
            '&$checked': {
                color: theme.palette.primary.main,
            },
        },
    }

    return theme
}
