import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Grid, Accordion, AccordionSummary, AccordionDetails, Typography } from "@material-ui/core"
import * as d3 from 'd3';
import TopFriends from "../TopFriends";


@inject('routing')
@observer
class CategoryResult extends Component {
  state = {
    expanded: false
  }

  constructor(props) {
    super(props);
    this.colorRange = ["#d7191c", "#ffffbf", "#1a9641"];
  }

  expand = () => {
    this.setState({
      expanded: !this.state.expanded
    })
  }

  scalePowerColor = (value, domain, exponent) => {
    // console.log(value, domain)
    const color = d3
      .scalePow()
      .exponent(exponent)
      .range(this.colorRange)
      .domain(domain);
    const result = color(value);
    return result;
  }

  render() {
    const c = this.props.c;
    const expanded = this.state.expanded;
    return (
      <Accordion onChange={this.expand} expanded={expanded} disabled={this.props.analyzing}>
        <AccordionSummary>
          <Grid container item xs={12} spacing={0} justify={"space-between"}>
            <Grid item xs={10}>
              <Typography variant={"caption"} noWrap>{c.category}</Typography>
            </Grid>
            <Grid container item xs justify={'flex-end'}>
              <Typography style={{color: this.scalePowerColor(c.score, this.props.domain, 0.03)}} variant={"caption"}>
                {c.score.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <TopFriends
            experiment={this.props.experiment}
            normative={this.props.normative}
            expType={this.props.expType}
            normType={this.props.normType}
            category={this.props.c.category}
            autoTrigger={false}
            trigger={expanded}
          />
        </AccordionDetails>
      </Accordion>
    )
  }
}

export default CategoryResult;