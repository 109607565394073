import React, {Component} from 'react';
import { Provider as MobxProvider, observer } from "mobx-react";
import { syncHistoryWithStore, RouterStore } from "mobx-react-router";
import { createBrowserHistory } from "history";
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from "@material-ui/core";
import { getDefaultTheme } from './default-theme';
import AppStore from "./stores/appstore";
import Home from "./components/Home";
import Login from "./components/Login";

const routing = new RouterStore();
const syncedHistory = syncHistoryWithStore(createBrowserHistory(), routing);


@observer
class App extends Component {
  state = {
    loading: true,
  };

  constructor(props) {
    super(props);
    this.store = new AppStore(routing, syncedHistory);
  }

  componentDidMount() {
    this.store.init().then(() => (
      this.setState({loading: false})
    ));
  }

  render() {
    const theme = getDefaultTheme();
    const loading = this.state.loading;
    return (
      <MobxProvider appstore={this.store} routing={routing}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline/>
          {!loading ?
            <Home/>
            :
            <Login isProcessing={true}/>
          }
        </MuiThemeProvider>
      </MobxProvider>
    );
  }
}

export default App;
