import { observable, action, computed } from "mobx";
import Simdoc from "../services/simdoc";
import custompanelstore from "./custompanelstore";


export default class Dashboardstore {
  @observable loading = true;
  @observable drawerOpen = true;
  @observable analyzing = false;
  @observable selectionType = {
    "experiment": "specialty",
    "normative": "specialty"
  }
  @observable selectedSpecialty = '';
  @observable selectedNormativeSpecialty = 'allhcps';
  @observable specialties = [];
  @observable audience = [];
  @observable audienceNPIs = [];
  @observable normative = [];
  @observable normativeNPIs = [];
  @observable categories = [];
  @observable audience_categories = [];
  @observable source = 0;
  @observable statsocialCategoryLevel = '2';
  @observable statsocialCategories = [];

  constructor(routing) {
    this.routing = routing;
    this.simdoc = new Simdoc(routing);
    this.init().then(() => (
      this.loading = false
    ))
    this.customPanelStores = {
      "experiment": new custompanelstore(routing),
      "normative": new custompanelstore(routing),
    }
  }

  @action
  async init() {
    await Promise.all([
      this.loadSpecialties(),
      this.loadCategories(),
      this.loadStatsocialCategories()
    ]);
  }

  @action
  setSelectionType = (value, panel) => {
    this.selectionType[panel] = value;
  }

  @action
  setSource = (event, newSource) => {
    this.source = newSource
  }

  @action
  loadSpecialties = async () => {
    const response = await this.simdoc.getGroupedSpecialties();
    if (response) {
      this.specialties = response.data['results'];
    }
  }

  @action
  openDrawer = e => {
    this.drawerOpen = true;
  }

  @action
  closeDrawer = e => {
    this.drawerOpen = false;
  }

  parseCategories = data => {
    const catdata = {'Uncategorized': []};
    for (const cat of data['results']) {
      if (cat['hidden']) {
        continue
      }
      if (cat['group']) {
        if (catdata[cat.group]) {
          catdata[cat.group].push(cat.category)
        } else {
          catdata[cat.group] = [cat.category]
        }
      } else {
        catdata['Uncategorized'].push(cat.category)
      }
    }
    return catdata;
  }

  @action
  loadCategories = async () => {
    const response = await this.simdoc.listCategoryGroups();
    if (response) {
      this.categories = this.parseCategories(response.data);
    }
  }

  @action
  loadStatsocialCategories = async () => {
    const response = await this.simdoc.getStatsocialCategories("2");
    if (response) {
      this.statsocialCategories = response.data;
    }
  }

  @action
  addAudience(accounts) {
    this.audienceNPIs = [];
    this.audience = accounts;
    this.analyzeAudience();
  }

  @action
  addNormative(accounts) {
    this.normativeNPIs = [];
    this.normative = accounts;
    this.analyzeAudience();
  }

  @action
  addNPIs(npis, panel) {
    if (panel === 'experiment') {
      this.audience = []
      this.audienceNPIs = npis
    } else if (panel === 'normative') {
      this.normative = []
      this.normativeNPIs = npis
    }
    this.analyzeAudience();
  }

  @action
  updateThreshold = thresh => {
    this.thresh = thresh;
  }

  @action
  selectSpecialty = async (spec) => {
    this.selectedSpecialty = spec;
    const response = await this.simdoc.groupAccounts(spec);
    if (response) {
      this.addAudience(response.data)
    }
  }

  @action
  selectNormativeSpecialty = async (spec) => {
    this.selectedNormativeSpecialty = spec;
    if (spec === 'allhcps') {
      this.addNormative([])
    } else {
      const response = await this.simdoc.groupAccounts(spec);
      if (response) {
        this.addNormative(response.data)
      }
    }
  }

  @action
  analyzeAudience = async () => {
    this.analyzing = true;
    this.drawerOpen = false;
    await Promise.all([
      this.loadTopCategories(),
    ]);
    this.analyzing = false;
  }

  @computed
  get normativeIdType() {
    if (this.normative.length > 0) {
      return 'twitter'
    }
    if (this.normativeNPIs.length > 0) {
      return 'npi'
    }
    return 'none'
  }

  @computed
  get experimentIdType() {
    if (this.audience.length > 0) {
      return 'twitter'
    }
    if (this.audienceNPIs.length > 0) {
      return 'npi'
    }
    return 'none'
  }

  @computed
  get normativeIds() {
    switch (this.normativeIdType) {
      case "twitter":
        return this.normative
      case "npi":
        return this.normativeNPIs
      case "none":
        return null
      default:
        return null
    }
  }

  @computed
  get experimentIds() {
    switch (this.experimentIdType) {
      case "twitter":
        return this.audience
      case "npi":
        return this.audienceNPIs
      case "none":
        return [];
      default:
        throw new Error("Unknown Experiment Type");
    }
  }

  @action
  loadTopCategories = async () => {
    const response = await this.simdoc.topCategories(this.experimentIds, this.experimentIdType, this.normativeIds, this.normativeIdType);
    if (response) {
      this.audience_categories = response.data
    }
  }

  @action
  exportData = () => {
    let catmap = {}
    Object.keys(this.categories).forEach(name => {
      for (let label of this.categories[name]) {
        catmap[label] = name
      }
    });
    let csvContent = "data:text/csv;charset=utf-8,";
    const headers = [
      "category",
      "interest",
      "score",
    ].join(",");
    csvContent += headers + "\r\n";
    this.audience_categories.forEach(function(row) {
      let cat = catmap[row.category]
      if (cat) {
        let csvRow = [
          cat,
          row.category,
          row.score
        ].join(",");
        csvContent += csvRow + "\r\n";
      }
    });
    const encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "interests.csv");
    document.body.appendChild(link); // Required for FF
    link.click();
  }

}