import axios from 'axios';


export default class Simdoc {
  ENV_HOSTNAMES = {
    '10.0.0.163': 'local',
    'localhost': 'local',
    'w2olocal.com': 'local',
    'w2odev.com': 'dev',
    'w2otest.com': 'test',
    'w2odst.com': 'prod',
    'w2oanalytics.com': 'prod'
  }
  HOSTS = {
    'local': '',
    'dev': 'https://api.socialgraphics.w2odev.com',
    'test': 'https://api.socialgraphics.w2otest.com',
    'stag': 'https://api.hcpsocialgraphics.w2odst.com',
    'prod': 'https://api.hcpsocialgraphics.w2odst.com',
  }

  analyzeEnv() {
    const hostname = window.location.hostname;
    for (const key of Object.keys(this.ENV_HOSTNAMES)) {
      if (hostname.endsWith(key)) {
        return this.ENV_HOSTNAMES[key]
      }
    }
    return 'dev'
  }

  constructor({routing=null, env=null}={}) {
    this.routing = routing;
    if (!env) {
      this.env = this.analyzeEnv();
    } else {
      this.env = env;
    }
    this.populateSession();
  }

  populateSession = () => {
    const foundSession = localStorage.getItem('sessionToken');
    if (foundSession) {
      this.session = foundSession;
    } else {
      this.session = 'ijg094g09j'
    }
  }

  getHost(env=this.env) {
    return this.HOSTS[env]
  }

  setEnv = env => {
    this.env = env;
  }

  formatParam = param => {
    return `${param.key}=${encodeURIComponent(param.value)}`
  }

  formatURL(path='', params=[]) {
    const host = this.getHost()
    const formatted = `${host}${path}`;
    if (params.length > 0) {
      return `${formatted}?${params.map(this.formatParam).join('&')}`
    }
    return formatted
  }

  async getSpecialties() {
    try {
      const specs = await this.request({
        method: 'GET',
        path: '/taxonomy/list',
        authenticated: true
      })
      return specs;
    } catch (e) {
      console.log(e);
    }
  }

  async getGroupedSpecialties() {
    try {
      const specs = await this.request({
        method: 'GET',
        path: '/taxonomy/list',
        params: [{key: "grouped", value: true}],
        authenticated: true
      })
      return specs;
    } catch (e) {
      console.log(e);
    }
  }


  async specialtyAccounts(spec) {
    try {
      const specs = await this.request({
        method: 'GET',
        path: `/taxonomy/specialty`,
        params: [{key: "s", value: spec}],
        authenticated: true
      })
      return specs;
    } catch (e) {
      console.log(e);
    }
  }

  async groupAccounts(group) {
    try {
      const ids = await this.request({
        method: 'GET',
        path: `/taxonomy/group`,
        params: [{key: "group", value: group}],
        authenticated: true
      })
      return ids;
    } catch (e) {
      console.log(e);
    }
  }

  async topFriends(experiment, expType, normative, normType, orderBy='count', thresh=10, limit=100, order='desc', category=null, filtered=true) {
    const params = [
      {key: 'orderby', value: orderBy},
      {key: 'order', value: order},
      {key: 'thresh', value: thresh},
      {key: 'limit', value: limit},
      {key: 'filtered', value: filtered}
    ]
    if (category) {
      params.push({key: 'category', value: category})
    }
    try {
      const friends = await this.request({
        method: 'POST',
        path: `/analysis/top/friends`,
        params: params,
        data: {experiment: experiment, normative: normative, experimentType: expType, normativeType: normType},
        authenticated: true
      })
      return friends;
    } catch (e) {
      console.log(e);
    }
  }

  async topAffinities(experiment, expType, normative, normType, orderBy='count', thresh=10, limit=100, order='desc', category=null) {
    const params = [
      {key: 'orderby', value: orderBy},
      {key: 'order', value: order},
      {key: 'thresh', value: thresh},
      {key: 'limit', value: limit}
    ]
    if (category) {
      params.push({key: 'category', value: category})
    }
    try {
      const affinities = await this.request({
        method: 'POST',
        path: `/analysis/top/affinities`,
        params: params,
        data: {experiment: experiment, normative: normative, experimentType: expType, normativeType: normType},
        authenticated: true
      })
      return affinities;
    } catch (e) {
      console.log(e);
    }
  }

  async topCategories(experiment, expType, normative, normType) {
    try {
      const cats = await this.request({
        method: 'POST',
        path: `/analysis/top/categories`,
        data: {experiment: experiment, normative: normative, experimentType: expType, normativeType: normType},
        authenticated: true
      })
      return cats;
    } catch (e) {
      console.log(e);
    }
  }

  async lookalikeAccounts(category) {
    try {
      const cats = await this.request({
        method: 'POST',
        path: `/analysis/category/friends`,
        params: [{key: "category", value: category}],
        authenticated: true
      })
      return cats;
    } catch (e) {
      console.log(e);
    }
  }

  async listCategoryGroups() {
    try {
      const groups = await this.request({
        method: 'GET',
        path: '/category/list',
        authenticated: true
      })
      return groups
    } catch (e) {
      console.log(e)
    }
  }

  async updateCategoryGroup(category, group, hidden) {
    const tmpParams = [
      {key: 'category', value: category},
      {key: 'group', value: group},
      {key: 'hidden', value: hidden}
    ]
    const params = tmpParams.filter(p => (p.value !== null));
    try {
      await this.request({
        method: 'POST',
        path: '/category/update',
        params: params,
        authenticated: true
      })
    } catch(e) {
      console.log(e)
    }
  }

  async updateTaxonomyGroup(taxonomy, group, hidden) {
    const tmpParams = [
      {key: 'taxonomy', value: taxonomy},
      {key: 'group', value: group},
      {key: 'hidden', value: hidden}
    ]
    const params = tmpParams.filter(p => (p.value !== null));
    try {
      await this.request({
        method: 'POST',
        path: '/taxonomy/update',
        params: params,
        authenticated: true
      })
    } catch(e) {
      console.log(e)
    }
  }

  async validateNPIs(userInput) {
    try {
      return await this.request({
        method: 'POST',
        path: `/validate/npis`,
        data: {npis: userInput},
        authenticated: true
      });
    } catch (e) {
      return false
    }
  }

  async resyncAccounts() {
    try {
      return await this.request({
        method: 'POST',
        path: "/account/resync",
        authenticated: true
      });
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async classifyAccount(profile, category) {
    try {
      return await this.request({
        method: 'POST',
        path: "/account/classify",
        params: [{key: "category", value: category}],
        data: {...profile},
        authenticated: true
      });
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async unclassifyAccount(profile, category) {
    try {
      return await this.request({
        method: 'DELETE',
        path: "/account/classify",
        params: [{key: "category", value: category}],
        data: {...profile},
        authenticated: true
      });
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async getAccount(twitterId) {
    try {
      return await this.request({
        method: 'GET',
        path: "/profile/account",
        params: [{key: 'twitter_id', value: twitterId}],
        authenticated: true
      });
    } catch (e) {
      throw e;
    }
  }

  async getTwitterProfile(twitterId) {
    try {
      return await this.request({
        method: 'GET',
        path: "/profile/social",
        params: [{key: 'twitter_id', value: twitterId}],
        authenticated: true
      });
    } catch (e) {
      throw e;
    }
  }

  async getTwitterProfilesBatch(twitterIds) {
    try {
      return await this.request({
        method: 'GET',
        path: '/profile/social/batch',
        params: [{key: 'twitter_ids', value: twitterIds.join(',')}],
        authenticated: true
      });
    } catch (e) {
      throw e;
    }
  }

  async getStatsocialCategories(level) {
    try {
      return await this.request({
        method: 'GET',
        path: '/taxonomy/statsocial/categories',
        params: [{key: "level",  value: level}],
        authenticated: true
      });
    } catch (e) {
      throw e;
    }
  }

  async request({method, path, params=[], data=null, headers={}, authenticated=true}={}) {
    if (authenticated) {
      headers['Authorization'] = `session ${this.session}`;
    }
    let options = {
      method: method,
      url: this.formatURL(path, params),
      headers: headers
    };
    if (data) {
      options['data'] = data
    }
    const response = await axios(options).then(response => (
      response
    )).catch(error => {
      if (error.response) {
        console.log(error.response);
        console.log(this.routing);
        if (error.response.status === 401) {
          this.routing.push('/login');
        } else {
          throw error;
        }
      }})
    return response;
  }

}