import axios from 'axios';


export default class Gemini {
  HOSTS = {
    'dev': 'https://api.rcdais.com',
    'test': 'https://api.rcdais.com',
    'stag': 'https://api.rcdais.com',
    'prod': 'https://api.rcdais.com',
  }

  constructor({routing=null, env="dev"}={}) {
    this.routing = routing;
    this.env = env;
    this.populateSession();
  }

  populateSession = () => {
    const foundSession = localStorage.getItem('sessionToken');
    if (foundSession) {
      this.session = foundSession;
    } else {
      this.session = 'ijg094g09j'
    }
  }

  getHost(env=this.env) {
    return this.HOSTS[env]
  }

  setEnv = env => {
    this.env = env;
  }

  formatParam = param => {
    return `${param.key}=${param.value}`
  }

  formatURL(path='', params=[]) {
    const host = this.getHost()
    const formatted = `${host}${path}`;
    if (params.length > 0) {
      return `${formatted}?${params.map(this.formatParam).join('&')}`
    }
    return formatted
  }

  async login(username, password) {
    try {
      const response = await this.request({
        method: 'POST',
        path: '/gemini/authenticate',
        data: `login=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        authenticated: false
      })
      localStorage.setItem('sessionToken', response.headers.session)
      this.populateSession();
      this.routing.push('/')
      return response
    } catch(e) {
      throw e
    }

  }

  logout() {
    localStorage.setItem('sessionToken', undefined);
    this.populateSession();
    this.routing.push('/login');
  }

  async getUserInfo() {
    try {
      const user = await this.request({
        method: 'GET',
        path: '/gemini/me',
        authenticated: true
      })
      return user;
    } catch (e) {
      this.routing.push('/login')
    }
  }

  async request({method, path, params=[], data={}, headers={}, authenticated=true}={}) {
    if (authenticated) {
      headers['Authorization'] = `session ${this.session}`;
    }
    let options = {
      method: method,
      url: this.formatURL(path, params),
      data: data,
      headers: headers
    };
    const response = await axios(options).then(response => (
      response
    ))
    return response;
  }

}