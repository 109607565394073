import { observable, action } from "mobx";
import Simdoc from "../services/simdoc";
import BatchProcessingQueue from "../utils/BatchProcessingQueue";


export default class TopFriendsStore {
  @observable category = null;
  @observable analyzing = false;
  @observable friendOrderBy = 'chi_norm';
  @observable orderDirection = 'desc';
  @observable thresh = 5;
  @observable audience = [];
  @observable audienceType = 'twitter'
  @observable normative = [];
  @observable normativeType = 'twitter'
  @observable friends = [];
  @observable filtered = true;

  constructor({routing, experiment, normative, experimentType, normativeType, category=null, source="twitter"}={}) {
    this.routing = routing;
    this.audience = experiment;
    this.audienceType = experimentType;
    this.normative = normative;
    this.normativeType = normativeType;
    this.source = source;
    this.simdoc = new Simdoc(routing);
    this.queue = new BatchProcessingQueue({batchSize: 100, maxEventLoopDurationSeconds: 2, batchExecuteFunction: this.hydrateTwitterIds})
    this.category = null;
    this.statsocialCategory = null;
    this.setCategory(category)
  }

  hydrateTwitterIds = items => {
    const twitterIdMap = {};
    for (const item of items) {
      twitterIdMap[item.item.toString()] = item.id;
    }
    return this.simdoc.getTwitterProfilesBatch(Object.keys(twitterIdMap))
      .then(response => (
        response.data
      ))
      .then(data => {
        const results = [];
        for (const account of data.accounts) {
          results.push({
            "id": twitterIdMap[account.id],
            "data": account
          })
        }
        return results
      })
  }

  @action
  setCategory = value => {
    this.category = value;
    this.statsocialCategory = `category_2=${value}`
    this.analyzeAudience();
  }

  @action
  toggleFiltered = e => {
    this.filtered = !this.filtered;
    this.analyzeAudience();
  }

  @action
  setOrderBy = e => {
    const orderBy = e.target.value;
    if (this.friendOrderBy !== orderBy) {
      this.friendOrderBy = orderBy;
      this.analyzeAudience();
    }
  }

  @action
  setOrderDirection = e => {
    const direction = e.target.value;
    if (this.orderDirection !== direction) {
      this.orderDirection = direction;
      this.analyzeAudience();
    }
  }

  @action
  updateThreshold = (e, v) => {
    if (this.thresh !== v) {
      this.thresh = v;
      this.analyzeAudience();
    }
  }

  @action
  addAudience(accounts, atype) {
    this.audience = accounts;
    this.audienceType = atype;
  }

  @action
  addNormative(accounts, ntype) {
    this.normative = accounts;
    this.normativeType = ntype;
  }

  @action
  updateCategory(category) {
    this.category = category;
  }

  @action
  analyzeAudience = async () => {
    this.analyzing = true;
    this.friends = [];
    if (this.source === 'twitter') {
      await Promise.all([
        this.loadTopFriends()
      ]);
    } else if (this.source === 'statsocial') {
      await Promise.all([
        this.loadTopAffinities()
      ]);
    }
    this.analyzing = false;
  }

  @action
  loadTopFriends = async () => {
    let response;
    if (this.category) {
      response = await this.simdoc.topFriends(this.audience, this.audienceType, this.normative, this.normativeType, this.friendOrderBy, this.thresh, 100, this.orderDirection, this.category);
    } else {
      response = await this.simdoc.topFriends(this.audience, this.audienceType, this.normative, this.normativeType, this.friendOrderBy, this.thresh, 100, this.orderDirection, null, this.filtered);
    }
    if (response) {
      this.friends = response.data
    }
  }

  @action
  loadTopAffinities = async () => {
    let response;
    if (this.category) {
      response = await this.simdoc.topAffinities(this.audience, this.audienceType, this.normative, this.normativeType, this.friendOrderBy, this.thresh, 100, this.orderDirection, this.statsocialCategory);
    } else {
      response = await this.simdoc.topAffinities(this.audience, this.audienceType, this.normative, this.normativeType, this.friendOrderBy, this.thresh, 100, this.orderDirection, null);
    }
    if (response) {
      this.friends = response.data
    }
  }

  @action
  fetchLookalikeAccounts = async () => {
    // await this.simdoc.resyncAccounts();
    this.friends = [];
    this.analyzing = true;
    const response = await this.simdoc.lookalikeAccounts(this.category);
    if (response) {
      this.friends = response.data;
    }
    this.analyzing = false;
  }

  @action
  exportData = () => {
    let csvContent = "data:text/csv;charset=utf-8,";
    const headers = [
      "twitter_user_id",
      "twitter_screen_name",
      "total_followers",
      "total_friends",
      "panel_followers",
      "chi_squared_normative",
      "chi_squared_genpop"
    ].join(",");
    csvContent += headers + "\r\n";
    this.friends.forEach(function(row) {
        let csvRow = [
          `"=""${row.profile.id}"""`,
          row.profile.screen_name,
          row.profile.followers_count,
          row.profile.friends_count,
          row.count,
          row.chi_norm,
          row.chi_genpop
        ].join(",");
        csvContent += csvRow + "\r\n";
    });
    const encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "top_friends.csv");
    document.body.appendChild(link); // Required for FF
    link.click();
  }

}